import * as React from "react"
import { useState, useRef, useLayoutEffect} from "react"

import "../assets/css/contactSection.css"

import useWindowWidth from "../hooks/useWindowWidth"

const ContactSection = () => {
	
	const [ formSent, setFormSent] = useState( false)
	const [ formMessage, setFormMessage] = useState( "Zahvaljujemo na prijavi")
	const [ height, setHeight] = useState( "auto")
	
	const formRef = useRef()
	
	const windowWidth = useWindowWidth()

	useLayoutEffect( () => {
		
		if( typeof formRef.current !== "undefined"){
			let tempHeight = formRef.current?.getBoundingClientRect().height + parseInt( window.getComputedStyle( formRef.current?.firstElementChild).getPropertyValue( "padding-top")) + parseInt( window.getComputedStyle( formRef.current?.lastElementChild.previousElementSibling).getPropertyValue( "padding-bottom"))
			
			setHeight(tempHeight)
		}
	},[windowWidth])
	
	const finalizeForm = ( e) => {
		
		if( e.propertyName === "height")
			setFormSent( true)
	}
	
	const handleFormSubmit = ( event) => {
				
		fetch('https://mlinar-nagradna.dev.koona.tech/nagradna-add.php', 
			{
				method: 'POST',
				headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  'Access-Control-Allow-Origin':'*',
				  'Access-Control-Allow-Credentials': 'true',
				  'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
				  'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
				},
				referrerPolicy: 'no-referrer',
				body: JSON.stringify( Object.fromEntries( new FormData( formRef.current)))
			}
		).then( res => res.json()).then( (res) => { 
			if( res.status === "400")
				setFormMessage( res.message)
			setHeight( 0)

		}).catch (function (error) {
		})
		
		event.preventDefault()
	}
	
	return(
		<>
			{ !formSent ?
				<form style={{ height: height, transition:"all 0.5s ease", overflow: "hidden"}} ref={ formRef} className="kn-contact-form" onSubmit={ handleFormSubmit} onTransitionEnd={finalizeForm}>
					<div className="kn-contact-group">
						<label htmlFor="name">Ime</label>
						<input type="text" id="name" name="name" placeholder="Ime" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="surname">Prezime</label>
						<input type="text" id="surname" name="surname" placeholder="Prezime" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="address">Ulica i kućni broj</label>
						<input type="text" id="address" name="address" placeholder="Ulica i kućni broj" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="post-number">Poštanski broj</label>
						<input type="text" id="post-number" name="post-number" placeholder="Poštanski broj" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="city">Grad / mjesto</label>
						<input type="text" id="city" name="city" placeholder="Grad / mjesto" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="phone">Broj mobitela</label>
						<input type="text" id="phone" name="phone" placeholder="Broj mobitela" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="email">Email</label>
						<input type="text" id="email" name="email" placeholder="Email" required />
					</div>
					<div className="kn-contact-group">
						<label htmlFor="bill-number">Broj računa</label>
						<input type="text" id="bill-number" name="bill-number" placeholder="Broj računa" required />
					</div>
					<div className="kn-contact-group kn-submit-button-group">
						<input type="submit" className="kn-send-button" value="Pošalji"/>
						<span className="kn-notification-text">*Odnosi se na proizvode brendova Jamnica, Sensation, Jana, Jana Vitamin i Jana Ice Tea.</span>
					</div>
				</form>
				:
				<h3 className="kn-mlinar-zahvaljujemo">{formMessage}</h3>
			}
		</>
	)
}
export default ContactSection
