import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ContactSection from "../components/contactSection"
import Seo from "../components/seo"

import "../assets/css/index.css"
import useWindowWidth from "../hooks/useWindowWidth"

const IndexPage = () => {

	const windowWidth = useWindowWidth()

	return(
		<Layout>
			<Seo title="Početna" />
			{windowWidth >= 371 || windowWidth === 0 ?
				<StaticImage
					src="../assets/images/mlinar-desktop.jpg"
					quality={100}
					formats={["auto", "webp", "avif"]}
					alt="Mlinar nagradna igra naslovna"
					style={{ width: `100%` }}
				/>
				:
				<StaticImage
					src="../assets/images/mlinar-mobile.jpg"
					quality={100}
					formats={["auto", "webp", "avif"]}
					alt="Mlinar nagradna igra naslovna"
					style={{ width: `100%` }}
				/>
			}
			<h1 className="kn-mlinar-title">Mlinar i Jamnicu spoji i super nagrade osvoji!</h1>
			<p className="kn-mlinar-paragraph">U periodu od 18.10. do 31.10.2021. do MLINARA skoči i super nagradu osvoji! Sve što moraš je stvoriti svoju kombinaciju - Odaberi svoj Mlinar proizvod pun okusa, spoji ga s tvojim omiljenim pićem iz Jamnica asortimana* i igra počinje! Spremi račun i prijavi se na nagradnu igru gdje te čekaju super nagrade jer pravi spoj mora biti nagrađen.</p>
			<p className="kn-mlinar-paragraph">Podijeliti ćemo najnovije Samsung uređaje među kojima je trenutno najbolji preklopni smartphone na svijetu: Samsung Galaxy Z Fold 3, Samsung Galaxy Z Flip 3, Samsung Galaxy Watch 4 Classic.</p>
			<ContactSection />
		</Layout>
	)
}
export default IndexPage
